import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import DesignSystemWrapper from '@/web/src/DesignSystemWrapper';
import i18n from '@_plugins/i18n';
import { container as iocContainer } from '@_plugins/ioc';
import '@_plugins/yup.extension';
import AuthService from '@core/auth.service';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { store } from '@stores/konamiStore';
import { Provider as IocProvider } from 'context/Ioc.context';
import 'reflect-metadata';

// Import createRoot
import App from './App';
import './assets/style/move.scss';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';

const service = iocContainer.get<AuthService>(AuthService);
service.initializeToken();
LicenseInfo.setLicenseKey(process.env.VITE_MUI_PRO_LICENSE_KEY ?? '');

const container = document.getElementById('root'); // Get the container element
const root = createRoot(container!); // Create a root

root.render(
  <IocProvider container={iocContainer}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <StyledEngineProvider injectFirst>
          <DesignSystemWrapper>
            <CssBaseline />
            <App />
          </DesignSystemWrapper>
        </StyledEngineProvider>
      </I18nextProvider>
    </Provider>
  </IocProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
